import * as Yup from 'yup';
import constants from './constants';

const nameRegExp = new RegExp("^[a-zA-Z-.',]+( [a-zA-Z-.',]+)*$");

const firstLastEmailCharLimit = 50;
const maxCharacters = (label) =>
  `${label} must be at most ${firstLastEmailCharLimit} characters`;

const firstNameSchema = Yup.string()
  .required('First name is required')
  .max(firstLastEmailCharLimit, maxCharacters('First name'))
  .matches(nameRegExp, 'Please enter a valid first name');

const lastNameSchema = Yup.string()
  .required('Last name is required')
  .max(firstLastEmailCharLimit, maxCharacters('Last name'))
  .matches(nameRegExp, 'Please enter a valid last name');

const emailSchema = Yup.string()
  .email('Please input a valid email')
  .max(firstLastEmailCharLimit, maxCharacters('Email'))
  .required('Email is required');

const getPhoneSchema = (label) =>
  Yup.string()
    .required(`${label} is required`)
    .matches(/^[0-9]+$/, `${label} must be only digits`)
    .min(10, `${label} must be exactly 10 digits`)
    .max(10, `${label} must be exactly 10 digits`)
    .matches(/^[9]\d{9}$/, `Invalid ${label}`)
    .typeError(`${label} must be a number`);

const phoneSchema = getPhoneSchema('Phone number');
const contactSchema = getPhoneSchema('Contact number');

const passwordSchema = Yup.string()
  .required('Password is required')
  .min(8)
  .label('Password');

const confirmPasswordSchema = Yup.string()
  .required()
  .min(8)
  .label('Confirm Password');

const oldPasswordSchema = confirmPasswordSchema.label('Old Password');

const newPasswordSchema = confirmPasswordSchema.label('New Password');

const confirmNewPasswordSchema = confirmPasswordSchema.oneOf(
  [Yup.ref('newPassword'), null],
  'Please make sure your passwords match.'
);

//#region card schema

const cardNumberSchema = Yup.number()
  .typeError('Card Number must be a number')
  .required()
  .label('Card Number');

const monthSchema = Yup.number()
  .typeError('Month must be a number')
  .required()
  .label('Month');
const yearSchema = Yup.number()
  .typeError('Year must be a number')
  .required()
  .label('Year');

const cvvSchema = Yup.number()
  .typeError('CVV must be a number')
  .required()
  .label('CVV');
//#endregion

const distanceSchema = Yup.number()
  .typeError('Distance must be a number')
  .integer('Distance must be a whole number.')
  .min(
    constants.DEFAULT_DISTANCE,
    `Minimum of ${constants.DEFAULT_DISTANCE} ${constants.DISTANCE_UNIT}`
  )
  .max(
    constants.DISTANCE_MAX,
    `More than ${constants.DISTANCE_MAX} ${constants.DISTANCE_UNIT} is not allowed`
  )
  .required()
  .label('Distance');

const zipSchema = Yup.string().required().label('Zip Code');

function conditionalRequired(label, type, toField, equalToValue) {
  return Yup[type]().when(toField, {
    is: (toField) => toField === equalToValue,
    then: fieldRequired(label, type),
    otherwise: Yup[type](),
  });
}

function createSchema(object) {
  return Yup.object().shape(object);
}

function fieldRequired(label, type = 'string') {
  return Yup[type]().required().label(label);
}

function typeSchema(label, type) {
  return Yup[type]().label(label);
}

export default {
  firstLastEmailCharLimit,
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  phoneSchema,
  contactSchema,
  passwordSchema,
  confirmPasswordSchema,
  oldPasswordSchema,
  newPasswordSchema,
  confirmNewPasswordSchema,
  cardNumberSchema,
  monthSchema,
  yearSchema,
  cvvSchema,
  zipSchema,
  distanceSchema,
  conditionalRequired,
  createSchema,
  fieldRequired,
  typeSchema,
};
