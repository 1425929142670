import { useMemo } from 'react';
import * as eva from '@eva-design/eva';
import { useDispatch, useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import { ApplicationProvider } from '@ui-kitten/components';
import dayjs from 'dayjs';
import { ThemeService } from '@ui-kitten/components/theme/theme/theme.service';
import lodashIsEmpty from 'lodash/isEmpty';

import DrawerNavigator from './DrawerNavigator/Branded';
import RootNavigatorBase from './RootNavigatorBase';

import Checkout from '../Screens/Checkout/CheckoutWebView.branded';
import { shopSelector } from '../RTK/shop/selectors';
import { removeCheckoutDetails } from '../RTK/checkout';
import { setCart } from '../RTK/cart';
import { reset } from '../RTK/mealPlan';
import { changeWhenFilter } from '../RTK/filter';
import customMapping from '../Theme/mapping/index.branded';
import constants from '../Config/constants';

const Stack = createStackNavigator();

function BrandedWebsiteNavigator() {
  const dispatch = useDispatch();
  const shopData = useSelector(shopSelector);

  const website_theme = !lodashIsEmpty(shopData?.theme)
    ? JSON.parse(shopData?.theme)
    : {};

  const filteredTheme = useMemo(() => {
    if (lodashIsEmpty(website_theme)) return {};
    const theme = {
      ...website_theme,
      'text-primary-color': '#80807B',
      'color-success-default': website_theme.icon_color,
      'color-success-hover': website_theme.icon_color,
      'color-success-focus': website_theme.icon_color,
      'color-success-active': website_theme.icon_color,
      'color-basic-default': website_theme.icon_color,
      'color-basic-hover': website_theme.icon_color,
      'color-basic-focus': website_theme.icon_color,
      'color-basic-active': website_theme.icon_color,
      'color-primary-default': website_theme.icon_color,
      'color-primary-default-border': website_theme.icon_color,
      'color-primary-transparent-hover': website_theme.icon_color
        ? `${website_theme.icon_color}29`
        : '',
      'color-primary-500': website_theme.icon_color,
    };
    // remove object key with empty value, fallback color not working because of it
    // because ui kitten use the object key as value e.g: { text-primary-color: "text-primary-color" }
    return Object.keys(theme).reduce(
      (r, key) => (theme[key] && (r[key] = theme[key]), r),
      {}
    );
  }, [website_theme]);
  const store_theme = ThemeService.create(filteredTheme);

  const params = new URLSearchParams(document?.location?.search);
  const redirect_ts = params.get('session_ts') || 0;
  const landing_referrer = params.get('referrer') || document?.referrer || '';
  const utm_campaign = params.get('utm_campaign') || '';
  const utm_source = params.get('utm_source') || '';
  const last_redirect_ts = localStorage.getItem('branded_redirect_ts') || 0;

  const order_date = params.get('order_date') || null;
  const order_time = params.get('order_time') || null;

  // clear caches when the current session is technically new
  if (redirect_ts > Number(last_redirect_ts)) {
    if (shopData) {
      if (Boolean(order_time) && order_time !== 'asap_order_time') {
        const actual_order_date = new Date(`${order_date} ${order_time}`);
        const dayjsObject = dayjs(actual_order_date);
        const value = {
          label: dayjsObject.format(constants.DATE_DISPLAY_FORMAT),
          value: dayjsObject.toISOString(),
          date: dayjsObject.format(constants.DBDATE_FORMAT),
          time: dayjsObject.format(constants.DBTIME_FORMAT),
        };

        dispatch(changeWhenFilter(value));
      } else {
        dispatch(changeWhenFilter());
      }

      dispatch(removeCheckoutDetails(shopData?.id));
      dispatch(setCart([]));
      dispatch(reset());
      localStorage.setItem('branded_redirect_ts', redirect_ts);
      localStorage.removeItem('datePickerTouched');
    }
  }

  localStorage.setItem(
    'branded_redirect_referrer',
    utm_campaign || utm_source || landing_referrer
  );

  return (
    <ApplicationProvider
      {...eva}
      theme={{ ...eva.light, ...store_theme }}
      customMapping={customMapping}
    >
      <RootNavigatorBase>
        <Stack.Navigator
          screenOptions={{ headerShown: false }}
          initialRouteName="order"
        >
          <Stack.Screen name={'order'} component={DrawerNavigator} />
          <Stack.Screen name={'checkout'} component={Checkout} />
        </Stack.Navigator>
      </RootNavigatorBase>
    </ApplicationProvider>
  );
}

export default BrandedWebsiteNavigator;
