import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import { getCurrency } from '../../../Helper/Routines';
import ThemeStyle from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';

import PaymentMethods from '../../PaymentMethods';

import { ContentProps } from './types';
import constants from '../../../Config/constants';

const EventOrderSummaryContent: React.FC<ContentProps> = ({
  error,
  eventDate,
  eventTime,
  grandTotal,
  name,
  onlineFeeTotal,
  paymentMethod,
  ticketPrice,
  onInputChange,
  onPaymentMethodChange,
}) => {
  return (
    <View style={styles.container}>
      <Text
        category={constants.isWeb ? 's1' : 'h6'}
        numberOfLines={3}
        style={ThemeStyle.spacingBottomMedium}
        adjustsFontSizeToFit
      >
        {name}
      </Text>

      <View style={styles.dateContainer}>
        <Icon
          name={'calendar-outline'}
          fill={ThemeColor.green}
          style={styles.icon}
        />
        <Text
          appearance="hint"
          category="c1"
        >{`${eventDate} ${eventTime}`}</Text>
      </View>

      <View style={styles.feeRow}>
        <Text style={styles.feeName}>Ticket Price</Text>
        <Text style={styles.feeAmount}>{getCurrency(ticketPrice)}</Text>
      </View>

      <View style={styles.feeRow}>
        <Text style={styles.feeName}>Service Fee</Text>
        <Text style={styles.feeAmount}>{getCurrency(onlineFeeTotal)}</Text>
      </View>

      <View style={styles.totalRow}>
        <Text style={constants.isWeb && ThemeStyle.bold}>Grand Total</Text>
        <Text category="s2" style={constants.isWeb && ThemeStyle.bold}>
          {getCurrency(grandTotal)}
        </Text>
      </View>

      <PaymentMethods
        error={error}
        value={paymentMethod}
        onInputChange={onInputChange}
        onMethodChange={onPaymentMethodChange}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: constants.isWeb ? 0 : 20,
    backgroundColor: '#FFFFFF',
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  dateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  feeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE',
  },
  feeName: {
    fontSize: 16,
    color: '#14213D',
  },
  feeAmount: {
    fontSize: 16,
    color: '#14213D',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
  },
});

export default EventOrderSummaryContent;
