import { useState } from 'react';
import dayjs from 'dayjs';

import modals from '../../../Components/Sheets/modals';
import { ModalManager } from '../../../Components/Web/Modal/hooks/useModalManager';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import constants from '../../../Config/constants';
import useGoBack from '../../../Hooks/useGoBack';
import routeList from '../../../Routes/list';
import { EventDetails } from '../../../RTK/api/events/types';

type BuyEventTicketDataType = EventDetails & {
  datePicker?: {
    title: string;
    minDate: Date;
    maxDate: Date;
  };
};

type BuyTicketType = BuyEventTicketDataType & {
  selectedDate?: string;
};

const useBuyTicket = ({ isEventDetails = false } = {}) => {
  const goBack = useGoBack({ rootRoute: routeList.EVENT_TAB });
  const [buyEventTicketData, setBuyEventTicketData] = useState<
    BuyEventTicketDataType | undefined
  >(); // use for date picker of multiple event schedule

  const _onBuyTickets =
    (data: BuyTicketType, isScheduleSelected?: boolean) => async (e?: any) => {
      e?.preventDefault?.();
      const daysCount = dayjs(data.end_date).diff(data.start_date, 'day');
      if (daysCount <= 1 || isScheduleSelected) {
        let result: { reschedule?: boolean; soldout?: boolean };
        if (constants.isWeb) {
          result = await ModalManager.show(MODALPROMPT.eventOrderSummary, {
            payload: data,
          });
        } else {
          result = await modals.show(modals.eventOrderSummary, data);
        }
        if (result?.soldout) {
          if (isEventDetails) {
            goBack();
          }
        } else if (result?.reschedule) {
          setTimeout(_onBuyTickets(data), 500);
        }
      } else {
        const now = dayjs();
        const isStartDatePast = dayjs(data.start_date).isBefore(now);
        const start = isStartDatePast
          ? now.add(1, 'day') // if start date is past, use today + 1 day so on date picker first date is for tomorrow
          : dayjs(data.start_date);
        const end = dayjs(data.end_date).format('MMMM DD');
        const titlePrefix = 'Select event schedule';
        const dateRange = `${start.format('MMMM DD')} - ${end}`;
        if (constants.isWeb) {
          const result = await ModalManager.show(MODALPROMPT.eventDatePicker, {
            payload: {
              title: titlePrefix,
              subTitle: dateRange,
              minDate: new Date(data.start_date),
              maxDate: new Date(data.end_date),
            },
          });
          if (result?.value) {
            _onScheduleSelected(result.value, data);
          }
        } else {
          setBuyEventTicketData({
            ...data,
            datePicker: {
              title: `${titlePrefix}\n${dateRange}`,
              minDate: new Date(start.toISOString()),
              maxDate: new Date(data.end_date),
            },
          });
        }
      }
    };

  const _onScheduleSelected = async (date: string, data?: BuyTicketType) => {
    const startDate = dayjs(buyEventTicketData?.start_date || data?.start_date);
    // this ensure that the date is from the selected date, and time is from the event time
    // on mobile date picker, sometimes the date param time is not match with the start time of the event
    const selectedDate = dayjs(date)
      .hour(startDate.hour())
      .minute(startDate.minute())
      .second(startDate.second())
      .millisecond(startDate.millisecond())
      .toISOString();
    if (!constants.isWeb) {
      setBuyEventTicketData(undefined);
      await new Promise((resolve) => setTimeout(resolve, 500)); // wait propagate to close the schedule picker
    }
    _onBuyTickets({ ...(buyEventTicketData || data), selectedDate }, true)();
  };

  const _onScheduleSelectionCancelled = () => setBuyEventTicketData(undefined);

  return {
    buyEventTicketData, // mobile use
    onBuyTickets: _onBuyTickets, // cross platform use
    onScheduleSelected: _onScheduleSelected, // mobile use
    onScheduleSelectionCancelled: _onScheduleSelectionCancelled, // mobile use
  };
};

export default useBuyTicket;
