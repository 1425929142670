import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import dayjs from 'dayjs';
import lodashIsEmpty from 'lodash/isEmpty';

import HorizontalListing from '../../../Components/HorizontalListing';
import HorizontalListingHeading from '../../../Components/HorizontalListing/Heading';

import constants from '../../../Config/constants';
import { activeAddressSelector } from '../../../RTK/user/selectors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';
import { useGetEventsQuery } from '../../../RTK/api/events';

import EventCard from './EventCard';

const EventListSection = ({ title, isDistance, onBuyTickets }) => {
  const isOnLoadCalled = useRef<boolean>(false);
  const [page, setPage] = useState(1);
  const [eventListData, setEventListData] = useState([]);
  const activeAddress = useSelector(activeAddressSelector);

  const coordinates = activeAddress?.geo_json_point.coordinates;

  const params = isDistance
    ? {
        location: `${coordinates?.[1]},${coordinates?.[0]}`,
        event_date: constants.EVENT_RANGE_DATE,
      }
    : {
        event_date: dayjs()
          .add(constants.remainingWeekDays, 'day')
          .format(constants.DBDATE_FORMAT),
      };

  const {
    data: eventsData,
    isSuccess: isEventFetchSuccess,
    isFetching,
    isError,
  } = useGetEventsQuery(
    {
      page: page,
      ...params,
    },
    { skip: lodashIsEmpty(coordinates) }
  );

  useEffect(() => {
    if (isOnLoadCalled) {
      setPage(1);
    }
  }, [coordinates]);

  useEffect(() => {
    if (isEventFetchSuccess) {
      if (page === 1) {
        if (!isOnLoadCalled.current) {
          isOnLoadCalled.current = true;
        }
        setEventListData(
          eventsData?.result.filter((item) => item.available > 0)
        );
      } else {
        setEventListData((prev: Array<unknown>) => [
          ...prev,
          ...eventsData?.result.filter((item) => item.available > 0),
        ]);
      }
    }
  }, [eventsData?.result]);

  const listLoader = [
    { loading: true },
    { loading: true },
    { loading: true },
    ...(constants.isWeb ? [{ loading: true }, { loading: true }] : []), // on web, 5 loaders
  ];
  const data = isFetching ? listLoader : eventListData;

  const _renderItem = ({ isFirstOfList, isLastOfList, item }) => {
    const firstAndLastMargin = constants.isWeb ? 0 : globalSpacing;
    return (
      <View
        style={{
          marginLeft: isFirstOfList ? firstAndLastMargin : globalSpacing / 4,
          marginRight: isLastOfList ? firstAndLastMargin : globalSpacing / 4,
          marginBottom: 10,
        }}
      >
        <EventCard {...item} onBuyTickets={onBuyTickets} />
      </View>
    );
  };

  const _onEndReached = (isReached) => {
    if (isReached && eventsData.has_next_page) {
      setPage(eventsData.page);
    }
  };
  // if not loading and has error or data length is 0 (in english, if the section does not have data to display just hide it)
  if (!isFetching && (isError || eventListData.length === 0)) {
    return null;
  }

  return (
    <View>
      <View style={ThemeStyle.pageVerticalSpacingMedium}>
        <HorizontalListing
          leftContent={
            <View style={ThemeStyle.spacingBottomSmall}>
              <HorizontalListingHeading
                title={title}
                noHPadding={constants.isWeb}
              />
            </View>
          }
          rightContent={null}
          arrowStyles={
            constants.isWeb && [{ transform: [{ translateY: -40 }] }]
          } // Adjust for button height to truly center}}
          data={data}
          renderItem={_renderItem}
          onEndReachedDone={_onEndReached}
        />
      </View>
      <View style={ThemeStyle.sectionSeparator} />
    </View>
  );
};

export default EventListSection;
