import { fontWeights } from './';

export default {
  $schema: '../../../node_modules/@eva-design/dss/schema/schema.json',
  version: 1.0,
  strict: {
    'text-font-family': 'Poppins-Regular',
    'text-heading-1-font-size': 36,
    'text-heading-1-font-weight': fontWeights.BOLD,
    'text-heading-1-font-family': '$text-font-family',
    'text-heading-2-font-size': 32,
    'text-heading-2-font-weight': fontWeights.BOLD,
    'text-heading-2-font-family': '$text-font-family',
    'text-heading-3-font-size': 30,
    'text-heading-3-font-weight': fontWeights.BOLD,
    'text-heading-3-font-family': '$text-font-family',
    'text-heading-4-font-size': 26,
    'text-heading-4-font-weight': fontWeights.BOLD,
    'text-heading-4-font-family': '$text-font-family',
    'text-heading-5-font-size': 22,
    'text-heading-5-font-weight': fontWeights.BOLD,
    'text-heading-5-font-family': '$text-font-family',
    'text-heading-6-font-size': 18,
    'text-heading-6-font-weight': fontWeights.BOLD,
    'text-heading-6-font-family': '$text-font-family',

    'text-subtitle-1-font-size': 17,
    'text-subtitle-1-font-weight': fontWeights.NORMAL,
    'text-subtitle-1-font-family': '$text-font-family',
    'text-subtitle-2-font-size': 16,
    'text-subtitle-2-font-weight': fontWeights.NORMAL,
    'text-subtitle-2-font-family': '$text-font-family',

    'text-paragraph-1-font-size': 15,
    'text-paragraph-1-font-weight': fontWeights.NORMAL,
    'text-paragraph-1-font-family': '$text-font-family',
    'text-paragraph-2-font-size': 13,
    'text-paragraph-2-font-weight': fontWeights.NORMAL,
    'text-paragraph-2-font-family': '$text-font-family',

    'text-caption-1-font-size': 12,
    'text-caption-1-font-weight': fontWeights.NORMAL,
    'text-caption-1-font-family': '$text-font-family',
    'text-caption-2-font-size': 10,
    'text-caption-2-font-weight': fontWeights.NORMAL,
    'text-caption-2-font-family': '$text-font-family',

    'text-label-font-size': 14,
    'text-label-font-weight': fontWeights.NORMAL,
    'text-label-font-family': '$text-font-family',

    'size-tiny': 24,
    'size-small': 32,
    'size-medium': 40,
    'size-large': 48,
    'size-giant': 56,
    'border-radius': 4,
    'border-width': 1,
  },
  components: {
    CheckBox: {
      appearances: {
        default: {
          variantGroups: {
            status: {
              success: {
                state: {
                  checked: {
                    borderColor: '#000',
                    backgroundColor: '#000',
                    iconTintColor: '#fff',
                  },
                  'checked.disabled': {
                    borderColor: 'text-primary-color',
                    backgroundColor: '#000',
                    iconTintColor: 'text-primary-color',
                  },
                  'checked.focused': {
                    borderColor: '#000',
                    backgroundColor: '#000',
                    iconTintColor: '#fff',
                  },
                  'checked.hover': {
                    borderColor: 'text-primary-color',
                    backgroundColor: 'text-primary-color',
                    iconTintColor: '#fff',
                  },
                },
              },
              basic: {
                state: {
                  disabled: {
                    borderColor: 'color-basic-transparent-disabled-border',
                    backgroundColor: 'transparent',
                    textColor: 'text-disabled-color',
                  },
                  focused: {
                    borderColor: 'text-primary-color',
                    backgroundColor: 'transaprent',
                    outlineBackgroundColor: 'outline-color',
                  },
                  hover: {
                    borderColor: 'text-disabled-color',
                    backgroundColor: 'text-disabled-color',
                  },
                },
              },
            },
          },
        },
      },
    },
    Radio: {
      appearances: {
        default: {
          variantGroups: {
            status: {
              success: {
                state: {
                  checked: {
                    borderColor: '#000',
                    backgroundColor: 'transparent',
                    iconTintColor: '#000',
                  },
                  'checked.disabled': {
                    borderColor: 'text-primary-color',
                    backgroundColor: 'transparent',
                    iconTintColor: 'text-primary-color',
                  },
                  'checked.focused': {
                    borderColor: '#000',
                    backgroundColor: 'transparent',
                    iconTintColor: '#000',
                  },
                  'checked.hover': {
                    borderColor: 'text-primary-color',
                    backgroundColor: 'transparent',
                    iconTintColor: 'text-primary-color',
                  },
                },
              },
              basic: {
                state: {
                  disabled: {
                    borderColor: 'color-basic-transparent-disabled-border',
                    backgroundColor: 'transparent',
                    textColor: 'text-disabled-color',
                  },
                  focused: {
                    borderColor: 'text-primary-color',
                    backgroundColor: 'transaprent',
                    outlineBackgroundColor: 'outline-color',
                  },
                  hover: {
                    borderColor: 'text-disabled-color',
                    backgroundColor: 'text-disabled-color',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
