import React, { Fragment, useEffect } from 'react';
import { View } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useMediaQuery } from 'react-responsive';

//HOOKS
import withDatePicker from '../../Hooks/withDatePicker';

//COMPONENTS
import CartList from '../../Components/Web/Cart/CartList';
import Header from '../../Components/Header/index.branded';
import RightDrawerContent from '../../Components/RightDrawerContent';
import SwitchTabOrderType from '../../Components/SwitchTabOrderType';

import ThemeStyle from '../../Theme/styles';

//STYLES
import { styles } from './';

//SCREENS
import Store from '../../Screens/Store';
import SchedulePicker from '../../Components/SchedulePicker';
import useSchedulePicker from '../../Components/SchedulePicker/index.controller';

const RightDrawer = createDrawerNavigator();

const RightDrawerScreen = (props) => {
  const xl = useMediaQuery({ query: '(min-width: 1200px' });

  useEffect(() => {
    if (xl) props.navigation.getParent('RightDrawer').closeDrawer();
  }, [xl, props.navigation]);

  return (
    <RightDrawerContent contentContainerStyle={ThemeStyle.flex1} {...props}>
      <View style={[ThemeStyle.flex1, styles.spacingRight]}>
        <SwitchTabOrderType size="medium" showMealPlan />
        <CartList
          isCartLoading={false}
          isClickableHeader={true}
          contentContainerStyle={{ flexGrow: 1 }}
        />
      </View>
    </RightDrawerContent>
  );
};

const DrawerNavigator = () => {
  const screenOptions = {
    drawerPosition: 'right',
    drawerStyle: {
      width: 400,
    },
    header: (props) => (
      <View>
        <Header {...props} />
      </View>
    ),
    sceneContainerStyle: {
      backgroundColor: '#fff',
    },
  };

  const {
    showReschedule,
    setRescheduleStoreId,
    rescheduleStoreId,
    rescheduleOnStorePage,
  } = useSchedulePicker();

  return (
    <Fragment>
      <RightDrawer.Navigator
        id="RightDrawer"
        defaultStatus="closed"
        useLegacyImplementation
        drawerContent={(props) => <RightDrawerScreen {...props} />}
        screenOptions={screenOptions}
        initialRouteName={'order'}
      >
        <RightDrawer.Screen
          name="order"
          component={Store}
          options={{
            unmountOnBlur: true,
          }}
        />
      </RightDrawer.Navigator>
      <SchedulePicker
        scheduleTitle="Select Reschedule"
        storeId={rescheduleStoreId}
        show={showReschedule}
        onConfirm={rescheduleOnStorePage}
        onCancel={setRescheduleStoreId}
      />
    </Fragment>
  );
};

export default withDatePicker(DrawerNavigator);
