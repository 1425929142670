import { View, Image, StyleSheet as RNStyleSheet } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import { useNavigation } from '@react-navigation/native';

import ThemeStyle from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';

import DateHelper from '../../../Helper/Date';

import Button from '../../../Components/Button';
import { ScaleButtonAnimation } from '../../../Components/Web/Intereaction';
import constants from '../../../Config/constants';
import RouteList from '../../../Routes/list';
import breakpoints from '../../../Theme/styles/breakpoints';
import EventCardLoader from './EventCardLoader';
import messages from '../../../Config/messages';
import { SECTION_SUB_TITLE, TITLE } from '../../../Theme/typographyProps';

interface EventCardProps {
  id: string;
  name: string;
  description: string;
  location_address: string;
  image: string;
  start_date: string;
  end_date: string;
  loading?: boolean;
  available: number;
  onBuyTickets: (data: any) => (e) => void;
  fullWidthOnMobile?: boolean;
}

const EventCard = (props) => {
  const {
    id,
    name,
    location_address,
    image,
    start_date,
    end_date,
    loading,
    available,
    onBuyTickets,
    fullWidthOnMobile = false,
  }: EventCardProps = props;

  const navigation = useNavigation<any>();
  const isSoldOut = available === 0;

  if (loading) {
    return <EventCardLoader />;
  }
  const _onEventPressTicket = () =>
    navigation.navigate(RouteList.EVENT_DETAILS, {
      eventId: id,
    });

  const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;

  return (
    <ClickableComponent
      key={id}
      plain
      onPress={_onEventPressTicket}
      href={`/event_details?eventId=${id}`}
    >
      <View
        dataSet={{
          media: fullWidthOnMobile
            ? ids.eventResponsiveMobileContainer
            : ids.eventResponsiveContainer,
        }}
        style={[
          styles.eventCardContainer,
          fullWidthOnMobile
            ? styles.eventResponsiveMobileContainer
            : styles.eventResponsiveContainer,
        ]}
      >
        <Image
          source={{ uri: image }}
          style={[
            styles.image,
            constants.isWeb && styles.additionalWebImageStyle,
          ]}
          resizeMode="cover"
        />
        <View style={styles.contentContainer}>
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.pageVerticalSpacingMedium,
              ThemeStyle.pageHorizontalSpacingSmall,
            ]}
          >
            <Text numberOfLines={1} {...TITLE}>
              {name}
            </Text>

            <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRowCenter]}>
              <Icon
                name="pin"
                fill={ThemeColor.green}
                style={[styles.icon, ThemeStyle.spacingRightSmall]}
              />
              <Text
                style={ThemeStyle.flex1}
                numberOfLines={1}
                {...SECTION_SUB_TITLE}
              >
                {location_address}
              </Text>
            </View>

            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Icon
                name="calendar-outline"
                fill={ThemeColor.green}
                style={[styles.icon, ThemeStyle.spacingRightSmall]}
              />
              <Text
                style={ThemeStyle.flex1}
                numberOfLines={1}
                {...SECTION_SUB_TITLE}
              >
                {DateHelper.formatDateRange(start_date, end_date)}
              </Text>
            </View>
          </View>

          <View style={ThemeStyle.spacingTopSmall}>
            <Button
              style={styles.button}
              status="success"
              onPress={onBuyTickets(props)}
              disabled={isSoldOut}
            >
              {() => (
                <Text
                  status={isSoldOut ? 'basic' : 'control'}
                  category={constants.isWeb ? 'p2' : 'p1'}
                  style={!constants.isWeb && ThemeStyle.bold}
                >
                  {isSoldOut ? 'SOLD OUT' : ' BUY TICKET'}
                </Text>
              )}
            </Button>
          </View>
        </View>
      </View>
    </ClickableComponent>
  );
};

const { ids, styles } = StyleSheet.create({
  eventCardContainer: {
    backgroundColor: 'white',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: 300,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  eventResponsiveContainer: {
    [`@media ${breakpoints.sm}`]: {
      width: constants.isWeb ? 400 : 300,
    },
  },
  eventResponsiveMobileContainer: {
    ...ThemeStyle.shadow,
    [`@media ${breakpoints.xs}`]: {
      width: '100%',
    },
    [`@media ${breakpoints.sm}`]: {
      width: 'auto',
    },
  },
  image: {
    width: '100%',
    aspectRatio: 2 / 1.1,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  additionalWebImageStyle: {
    backfaceVisibility: 'hidden', // Forces GPU rendering
    willChange: 'transform',
  },
  contentContainer: {
    justifyContent: 'space-between',
    flex: 1,
  },
  date: {
    fontSize: 14,
    color: '#666',
    marginBottom: 16,
  },
  button: {
    padding: 12,
    borderRadius: 4,
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  containerSoldOutOverlay: {
    ...RNStyleSheet.absoluteFillObject,
    zIndex: 1,
    overflow: 'hidden',
    borderRadius: 5,
  },
  contentSoldOutOverlay: {
    ...ThemeStyle.flexDirectionRowCenterCenter,
    backgroundColor: `#00000066`, // 40% black
  },
});

export default EventCard;
