import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  Card,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  Text,
  useTheme,
} from '@ui-kitten/components';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';

import constants from '../../../../Config/constants';

import storeApi from '../../../../Service/api/store';

import StoreHelper from '../../../../Helper/Store';

//THEMES
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';

import { checkout } from '../../../../RTK/defaultValues';
import messages from '../../../../Config/messages';
import { shopSelector } from '../../../../RTK/shop/selectors';
import {
  checkoutSelector,
  checkoutStoreInfoSelector,
} from '../../../../RTK/checkout/selectors';
import { MODALPROMPT } from './config';
import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';
import {
  AvailableOrderDates,
  CartDetails,
  TimeOptions,
} from '../../../../Types';
import { useQuery } from '@tanstack/react-query';
import useMealPlan from '../../../../Hooks/useMealPlan';
//@ts-ignore: JS Code error
import { RAZZLE_BUILD_MODE } from '@env';
import { View } from 'react-native';

const { ORDER_TYPES } = constants;

const RescheduleTextIfLimit = 'Do you want to change your order schedule?';
const RescheduleTextIfClosedOrClosing = 'Reschedule';

dayjs.extend(customParseFormat);

type ChangeDateTimePayload = {
  hasAsapAndPreorderBtn?: boolean;
  modalTitle?: string;
  value: {
    label?: string;
    value?: string;
    date?: string;
    time?: string;
    canAsap: boolean;
  };
  error: {
    errorType: string;
    title: string;
    message: string;
    unavailableItems: Array<CartDetails>;
  };
};

const ChangeDateTimeSchedule = () => {
  const isDataSetRef = useRef(false);
  const theme = useTheme();
  const shopData = useSelector(shopSelector);
  const checkoutData = useSelector(checkoutSelector);
  const checkoutParams = useSelector((state: any) => state.checkout.params);
  const storeInfo = useSelector(checkoutStoreInfoSelector);

  const { hideModalPrompt } = useModalPrompt();
  const { isMealPlan } = useMealPlan();
  const [data, setData] = useState<ChangeDateTimePayload>();
  const [isAsap, setAsap] = useState<boolean>(false);
  const [changeSchedule, setChangeSchedule] = useState(false);
  const [selectedAdvanceDay, setSelectedAdvanceDay] =
    useState<AvailableOrderDates>();
  const [selectedAdvanceTime, setSelectedAdvanceTime] = useState<TimeOptions>();

  const { error, value, modalTitle, hasAsapAndPreorderBtn } = data || {};

  const { data: storeHoursResult, isFetching: storeHoursLoading } = useQuery({
    enabled: Boolean(storeInfo?.store_id) && !isMealPlan, // since web is using it on meal plan and non meal plan order, prevent fetch if has mealPlanType
    queryFn: () => storeApi.getStoreOrderDates(storeInfo?.store_id),
    queryKey: [storeInfo?.store_id, 'store-hours'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const storeHours = storeHoursResult?.data || [];

  // hooks for setting default advance date & time onload
  useEffect(() => {
    if (!isDataSetRef.current && !lodashIsEmpty(data)) {
      if (!lodashIsEmpty(value)) {
        const { date, time } = _getSelectedData(value);
        setSelectedAdvanceDay(date);
        setSelectedAdvanceTime(time);
        setAsap(false);
      }
      isDataSetRef.current = true;
    }
  }, [data]);

  const _getSelectedData = (data: ChangeDateTimePayload['value']) => {
    const date = lodashFind(storeHours, { value: data.date });
    const timeOptions = date?.time_options || [];
    const time = lodashFind(timeOptions, { value: data.time });
    return {
      date,
      time,
    };
  };

  const scheduleParams =
    shopData !== null
      ? { ...shopData, store_id: storeInfo?.store_id }
      : checkoutParams;

  const storeCheckoutData = StoreHelper.getStoreCheckoutData(
    scheduleParams?.store_id,
    checkoutData
  );

  const hasError = !lodashIsEmpty(error?.errorType);
  const showSelector = RAZZLE_BUILD_MODE === 'branded' || hasAsapAndPreorderBtn;
  const showPreOrderOptions = !showSelector || (showSelector && !isAsap);

  const isClosingOrClosed =
    error?.errorType === messages.STORE_CLOSED_MOBILE.title ||
    error?.errorType === messages.STORE_CLOSING.title;

  const orderType = storeCheckoutData[checkout.keys.ORDER_TYPE];

  const isPickup = orderType?.value?.includes(ORDER_TYPES.PICKUP);

  const orderTypeLabel = `${isPickup ? 'Pickup' : 'Delivery'}`;

  const disableBtn = showSelector
    ? false
    : !selectedAdvanceDay?.value ||
      !selectedAdvanceTime?.value ||
      storeHoursLoading;

  const _format = (value: string, isDate: boolean) =>
    dayjs(value).format(
      isDate
        ? constants.DATE_ONLY_DISPLAY_FORMAT
        : constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
    );

  const _getDateTitle = (date: AvailableOrderDates) => {
    if (lodashIsEmpty(date)) return '';
    const { value } = date;
    return _format(value, true);
  };

  const _getTimeTitle = (time: TimeOptions) => {
    if (lodashIsEmpty(time)) return '';
    const { start, end } = time;
    return `${_format(start, false)} - ${_format(end, false)}`;
  };

  const onSelectAdvanceDate = async ({ row }: IndexPath) => {
    //get selected date
    const selectData = storeHours[row];
    setSelectedAdvanceDay(selectData);
    setSelectedAdvanceTime(undefined);
  };

  const onSelectAdvanceTime = async ({ row }: IndexPath) => {
    //get selected time
    const timeOptions = lodashFilter(
      selectedAdvanceDay.time_options,
      (e) => e.value !== 'asap_order_time'
    );
    const selectData = timeOptions[row];
    setSelectedAdvanceTime(selectData);
  };

  const _toggleOrderTime = () => {
    setSelectedAdvanceDay(undefined);
    setSelectedAdvanceTime(undefined);
    setAsap(!isAsap);
  };

  const _onPressProceed = async () => {
    if (
      !lodashIsEmpty(selectedAdvanceDay?.value) &&
      !lodashIsEmpty(selectedAdvanceDay?.value)
    ) {
      const updatedWhenFilter = {
        label: dayjs(
          new Date(
            `${selectedAdvanceDay?.value} ${selectedAdvanceTime?.value}`
          ).toISOString()
        ).format(constants.DATE_DISPLAY_FORMAT),
        value: new Date(
          `${selectedAdvanceDay?.value} ${selectedAdvanceTime?.value}`
        ).toISOString(),
        date: dayjs(selectedAdvanceDay?.value).format(constants.DBDATE_FORMAT),
        time: selectedAdvanceTime?.value,
      };
      setChangeSchedule(false);
      hideModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, updatedWhenFilter);
    } else if (showSelector) {
      const updatedWhenFilter = {
        label: 'ASAP',
        value: '',
        date: '',
        time: '',
      };
      setChangeSchedule(false);
      hideModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, updatedWhenFilter);
    }
  };
  const _onPressClosed = () => {
    setChangeSchedule(false);
    hideModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, {});
  };

  return (
    <ModalPrompt
      id={MODALPROMPT.changeDateAndTimeSchedule}
      title={
        !lodashIsEmpty(modalTitle)
          ? modalTitle
          : changeSchedule
          ? 'Change Schedule'
          : error?.title
      }
      dataSet={{ media: ids.mainWrapper }}
      mainContentWrapperStyle={styles.mainWrapper}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      onCloseButtonClick={_onPressClosed}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <Layout
        style={[ThemeStyle.spacingTopSmall, ThemeStyle.spacingBottomSmall]}
      >
        {hasError && !changeSchedule && (
          <Fragment>
            <Card
              style={[
                { backgroundColor: '#ffe2e6' },
                ThemeStyle.spacingBottomMedium,
              ]}
            >
              <Text category="p2" status="danger">
                {error?.message}
              </Text>
            </Card>
            <Button
              style={
                isClosingOrClosed
                  ? styles.rescheduleButtonAlignToCenter
                  : styles.rescheduleButton
              }
              onPress={() => {
                setChangeSchedule(true);
              }}
              status="success"
            >
              {() => (
                <Text category="c1" status="control">
                  {isClosingOrClosed
                    ? RescheduleTextIfClosedOrClosing
                    : RescheduleTextIfLimit}
                </Text>
              )}
            </Button>
          </Fragment>
        )}

        {(!hasError || changeSchedule) && (
          <Fragment>
            {showSelector && (
              <Layout style={[ThemeStyle.spacingBottom]}>
                <Layout style={styles.container}>
                  <Layout style={styles.layout} level="1">
                    <Button
                      onPress={!isAsap && _toggleOrderTime}
                      style={isAsap ? styles.enableBtn : styles.disableBtn}
                      status={isAsap ? 'success' : 'control'}
                    >
                      <View style={styles.innerBtn}>
                        <Text category="p2" status={isAsap && 'control'}>
                          ASAP
                        </Text>
                      </View>
                    </Button>
                  </Layout>

                  <Layout style={styles.layout} level="1">
                    <Button
                      onPress={isAsap && _toggleOrderTime}
                      style={!isAsap ? styles.enableBtn : styles.disableBtn}
                      status={!isAsap ? 'success' : 'control'}
                    >
                      <View style={styles.innerBtn}>
                        <Text category="p2" status={!isAsap && 'control'}>
                          Pre-Order
                        </Text>
                      </View>
                    </Button>
                  </Layout>
                </Layout>
              </Layout>
            )}
            {showPreOrderOptions && (
              <Layout style={[ThemeStyle.spacingBottomMedium]}>
                <Layout style={styles.container}>
                  <Layout style={styles.layout} level="1">
                    <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                      <Text category="label">{orderTypeLabel} Date</Text>
                    </Layout>
                    <Select
                      accessoryRight={
                        storeHoursLoading && (
                          <ActivityIndicator
                            color={theme['color-success-500']}
                          />
                        )
                      }
                      placeholder={
                        storeHoursLoading ? 'Loading...' : 'Select Date'
                      }
                      size="large"
                      value={_getDateTitle(selectedAdvanceDay)}
                      onSelect={onSelectAdvanceDate}
                    >
                      {storeHours.map(
                        (date: AvailableOrderDates, index: number) => (
                          <SelectItem key={index} title={_getDateTitle(date)} />
                        )
                      )}
                    </Select>
                  </Layout>

                  <Layout style={styles.layout} level="1">
                    <Layout style={ThemeStyle.spacingBottomSmall} level="1">
                      <Text category="label">{orderTypeLabel} Time</Text>
                    </Layout>
                    <Select
                      accessoryRight={
                        storeHoursLoading && (
                          <ActivityIndicator
                            color={theme['color-success-500']}
                          />
                        )
                      }
                      placeholder={
                        storeHoursLoading ? 'Loading...' : 'Select Time'
                      }
                      size="large"
                      value={_getTimeTitle(selectedAdvanceTime)}
                      onSelect={onSelectAdvanceTime}
                    >
                      {selectedAdvanceDay?.time_options
                        ?.filter?.(
                          (time: any) => time.value !== 'asap_order_time'
                        )
                        ?.map?.((time: TimeOptions, index: number) => (
                          <SelectItem key={index} title={_getTimeTitle(time)} />
                        ))}
                    </Select>
                  </Layout>
                </Layout>
              </Layout>
            )}

            <Button
              disabled={disableBtn}
              style={styles.proceedBtn}
              onPress={_onPressProceed}
              plain
            >
              <Text category="label" status="basic">
                Proceed
              </Text>
            </Button>
          </Fragment>
        )}
      </Layout>
    </ModalPrompt>
  );
};
const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    height: 'auto',
    [`@media ${BreakPoints.xs}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 500,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 550,
    },
  },
  container: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  layout: {
    width: '48%',
  },
  selectWhole: {
    flex: 1,
  },
  selectSmall: {
    flex: 1,
    margin: 2,
  },
  rescheduleButton: {
    ...ThemeStyle.spacingBottomMedium,
  },
  rescheduleButtonAlignToCenter: {
    ...ThemeStyle.spacingBottomMedium,
    ...ThemeStyle.alignSelfCenter,
  },
  enableBtn: {
    flex: 1,
    marginTop: 10,
    borderRadius: 0,
    height: 50,
  },
  disableBtn: {
    height: 50,
    borderRadius: 0,
    backgroundColor: ThemeColor.gray,
    flex: 1,
    marginTop: 10,
    opacity: 0.5,
    elevation: 0,
  },
  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  proceedBtn: {
    height: 50,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
});
export default React.memo(ChangeDateTimeSchedule);
