import { useEffect, useRef, useState } from 'react';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../../Config/constants';
import { useGetEventsQuery } from '../../../RTK/api/events';
import { useSelector } from 'react-redux';
import { eventFilterSelector } from '../../../RTK/filter/selectors';
import { activeAddressSelector } from '../../../RTK/user/selectors';
import dayjs from 'dayjs';

let onEndReachedCalledDuringMomentum = true;
const useEventViewModel = () => {
  const isOnLoadCalled = useRef<boolean>(false);
  const activeAddress = useSelector(activeAddressSelector);
  const eventFilter = useSelector(eventFilterSelector);
  const [page, setPage] = useState(1);
  const [numberofDays, setNumberOfDays] = useState<number>(eventFilter.value);
  const [eventListData, setEventListData] = useState([]);
  const coordinates = activeAddress?.geo_json_point.coordinates;

  const {
    data: eventsData,
    isLoading: isEventDataLoading,
    isFetching: isEventDataFetching,
    isSuccess: isEventFetchSuccess,
    refetch: refetchEventsData,
  } = useGetEventsQuery(
    {
      page: page,
      event_date: dayjs()
        .add(numberofDays, 'day')
        .format(constants.DBDATE_FORMAT),
      location: `${coordinates?.[1]},${coordinates?.[0]}`,
    },
    { skip: lodashIsEmpty(coordinates) }
  );

  useEffect(() => {
    if (isOnLoadCalled) {
      setPage(1);
      setNumberOfDays(eventFilter.value || 0);
    }
  }, [eventFilter.value, coordinates]);

  useEffect(() => {
    if (isEventFetchSuccess) {
      if (page === 1) {
        if (!isOnLoadCalled.current) {
          isOnLoadCalled.current = true;
        }
        setEventListData(eventsData?.result);
      } else {
        setEventListData((prev: Array<unknown>) => [
          ...prev,
          ...eventsData?.result,
        ]);
      }
    }
  }, [eventsData?.result]);

  const _onReachedEnd = () => {
    if (!onEndReachedCalledDuringMomentum && eventsData.has_next_page) {
      onEndReachedCalledDuringMomentum = true;
      setPage(eventsData.page);
    }
  };

  const _onMomentumScrollBegin = () => {
    if (isOnLoadCalled.current) {
      onEndReachedCalledDuringMomentum = false;
    }
  };

  // specific for web functions
  const _isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 30;
    const isNearBottom =
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;

    if (eventsData.has_next_page && isNearBottom && !isEventDataFetching) {
      setPage(eventsData.page);
    }
  };

  return {
    eventsData: isEventDataLoading
      ? [
          { loading: true },
          { loading: true },
          { loading: true },
          { loading: true },
        ]
      : eventListData,
    isEventDataLoading,
    isEventDataFetching,
    refetchEventsData,
    onReachedEnd: _onReachedEnd,
    onMomentumScrollBegin: _onMomentumScrollBegin,
    isCloseToBottom: _isCloseToBottom,
  };
};

export default useEventViewModel;
