import React, { Fragment, useRef, useState } from 'react';
import ActionSheet from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';

import commonProps from './commonProps';
import styles from './styles';

/**
 * useful data shape is
 * {
 *   title: string,
 *   message: string,
 *   highlight: [{ word: string, color: HTMLColor }],
 *   buttonStatus: string,
 *   buttonText: string,
 *   buttons: [{ text: string, onPress: Function or Promise, status: "ui kitten button status" }]
 * }
 */
function PromptSheet({ sheetId }) {
  const sheetRef = useRef();
  const [data, setData] = useState();

  const title = data?.title || '';
  const message = data?.message || '';
  const highlight = data?.highlight || [];
  const buttonStatus = data?.buttonStatus || '';
  const buttonText = data?.buttonText || '';
  const buttons = data?.buttons || [];

  const messageWords = message.split(' ');
  const hasButton =
    (Boolean(buttonStatus) && Boolean(buttonText)) || !lodashIsEmpty(buttons);

  // by using sheetRef.current.hide() instead of modals.hide() this fixed the issue of cannot hide after show inside of other action sheet
  const _onClose = () => sheetRef.current.hide({ buttonClicked: true });

  const _onButtonPress = (data) => async () => {
    data?.onPress?.();
    return sheetRef.current.hide(data);
  };

  const _renderButton = () => {
    if (Boolean(buttonStatus) && Boolean(buttonText)) {
      return (
        <Button status={buttonStatus} onPress={_onClose}>
          {buttonText}
        </Button>
      );
    } else if (!lodashIsEmpty(buttons)) {
      const isRow = buttons.length <= 2;
      return (
        <View
          style={isRow && [ThemeStyle.flexWrap, ThemeStyle.flexDirectionRow]}
        >
          {buttons.map((button, i) => (
            <Fragment key={i}>
              <Button
                status={button.status || 'success'}
                style={isRow && ThemeStyle.flex1}
                onPress={_onButtonPress(button)}
              >
                {(props) => (
                  <Text {...props} numberOfLines={isRow ? 1 : undefined}>
                    {button.text}
                  </Text>
                )}
              </Button>
              {/* If not last, add separator */}
              {i !== buttons.length - 1 && (
                <View
                  style={
                    isRow
                      ? ThemeStyle.rowSeparatorSmall
                      : ThemeStyle.spacingBottomSmall
                  }
                />
              )}
            </Fragment>
          ))}
        </View>
      );
    }
    return null;
  };

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE}>
          {title}
        </Text>

        <View style={hasButton && ThemeStyle.spacingBottom}>
          {lodashIsEmpty(highlight) ? (
            <Text category="p2">{message}</Text>
          ) : (
            <Text>
              {messageWords.map((word, index) => {
                const matchingHighlight = lodashFind(highlight, (e) =>
                  word.includes(e.word)
                );
                if (matchingHighlight) {
                  const excluded = word.replace(matchingHighlight.word, '');
                  return (
                    <Fragment key={index}>
                      <Text
                        style={[
                          ThemeStyle.bold,
                          { color: matchingHighlight.color },
                        ]}
                      >
                        {matchingHighlight.word}
                      </Text>
                      {excluded}{' '}
                    </Fragment>
                  );
                } else {
                  return `${word} `;
                }
              })}
            </Text>
          )}
        </View>

        {_renderButton()}
      </View>
    </ActionSheet>
  );
}

export default PromptSheet;
