import React, { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Select, SelectItem } from '@ui-kitten/components';
import { IndexPath } from '@ui-kitten/components/devsupport';
import lodashFind from 'lodash/find';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import constants from '../../../../Config/constants';
import ThemeStyle, { globalSpacing } from '../../../../Theme/styles';

import Button from '../../../Button';

import { ModalManager } from '../hooks/useModalManager';

import ModalPrompt from './ModalPrompt';

dayjs.extend(isBetween);

type Payload = {
  modalId: string;
  payload: {
    maxDate: Date;
    minDate: Date;
    mode: 'date' | 'time' | 'datetime'; // TODO - time & datetime when needed
    subTitle?: string;
    title: string;
    value?: string;
  };
};

// This component is form date picker range from minDate & maxDate from payload
const EventDatePicker = ({ modalId, payload }: Payload) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    payload.value
  );

  const mode = payload.mode || 'date'; // default mode is date
  const isDateMode = mode === 'date';
  const selectedDateDisplay = selectedDate
    ? dayjs(selectedDate).format(constants.DATE_ONLY_DISPLAY_FORMAT)
    : null;

  // create date array base on min and max date from payload
  const dateArray = useMemo(() => {
    if (!isDateMode || !payload.minDate || !payload.maxDate) return [];
    const startDate = dayjs(payload.minDate);
    const endDate = dayjs(payload.maxDate);
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate.isBetween(startDate, endDate, null, '[]')) {
      dateArray.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    return dateArray;
  }, [payload.minDate, payload.maxDate]);

  const _onFocus = () => {
    let testId = selectedDateDisplay;
    if (selectedDate) {
    } else {
      const asd = lodashFind(dateArray, (date) => date.isAfter(dayjs()));
      testId = asd.format(constants.DATE_ONLY_DISPLAY_FORMAT);
    }
    document
      .querySelector(`[data-testid="${testId}"]`)
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const _onSelectDate = (data: IndexPath) => {
    setSelectedDate(dateArray[data.row]);
  };

  const _onProceed = () => {
    ModalManager.hide(modalId, {
      payload: { value: dayjs(selectedDate).toISOString() },
    });
  };

  return (
    <ModalPrompt
      id={modalId}
      title={payload.title}
      footer={
        <View style={ThemeStyle.pageHorizontalSpacing}>
          <Button
            appearance="outline"
            status="success"
            style={styles.footerBtn}
            onPress={_onProceed}
          >
            {() => <Text category="p2">Proceed</Text>}
          </Button>
        </View>
      }
      hasHeader
      hasHeaderLine
    >
      {Boolean(payload.subTitle) && (
        <Text style={[ThemeStyle.textCenter, ThemeStyle.spacingBottomMedium]}>
          {payload.subTitle}
        </Text>
      )}
      <View
        style={[
          ThemeStyle.flex1,
          ThemeStyle.flexDirectionRow,
          ThemeStyle.spacingBottomMedium,
          // @ts-ignore: styles work, but typescript showing error because the style key is not working on mobile only on web
          { columnGap: globalSpacing },
        ]}
      >
        {/* Date picker */}
        <View style={ThemeStyle.flex1}>
          <Select
            placeholder="Select Date"
            size="large"
            value={selectedDateDisplay}
            onFocus={_onFocus}
            onSelect={_onSelectDate}
          >
            {dateArray.map((date, i) => {
              const dateDisplay = date.format(
                constants.DATE_ONLY_DISPLAY_FORMAT
              );
              const isSelected = selectedDateDisplay === dateDisplay;
              const title = dateDisplay;
              return (
                <SelectItem
                  testID={title}
                  disabled={date.isBefore(dayjs())}
                  key={i}
                  selected={isSelected}
                  title={title}
                />
              );
            })}
          </Select>
        </View>
        {/* TODO - Add time picker here base on minDate & maxDate, add when needed for now we only gonna add date picker */}
      </View>
    </ModalPrompt>
  );
};

const styles = StyleSheet.create({
  footerBtn: {
    borderRadius: 5,
    backgroundColor: '#fff',
  },
});

export default EventDatePicker;
